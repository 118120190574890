<template>
  <div class="auth-wrapper auth-v2">

    <b-row

      class="auth-inner m-0"
    >

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-bind="mainProps"
          src="@/assets/images/logo/logo_grupovia.png"
          fluid
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center"
      >
        <div
          class="
            w-100
            d-lg-flex
            align-items-center
            justify-content-center
            px-5
            mt-3
          "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
          :class="hashIsValid ? 'text-left' : 'text-center'"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Redefinir Senha
          </b-card-title>
          <b-card-text class="mb-2">
            {{ !hashIsValid ? 'Informe sua nova senha' : 'Link está inválido ou expirado' }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            v-if="hashIsValid"
            :default="{ errors }"
          >
            <b-form
              class="auth-reset-password-form mt-2"
              @submit.prevent
            >

              <!-- password -->
              <b-form-group
                label="Nova Senha"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirmar Senha"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar Senha"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                :disabled="errors"
                variant="primary"
                @click="resetPassword"
              >
                Trocar Senha
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" />  Voltar para tela de login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue';
import { required } from '@validations';
import store from '@/store/index';
import UserService from '@/service/user/user';

import { callLoading, removeDarkMode } from '@/service/utilities/index';

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BCardTitle,
        BCardText,
        BForm,
        BFormGroup,
        BImg,
        BInputGroup,
        BLink,
        BFormInput,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: '',
            cPassword: '',
            password: '',
            sideImg: require('@/assets/images/pages/login-v2.png'),
            hash: this.$route.params.hash,
            required,
            password1FieldType: 'password',
            password2FieldType: 'password',
            mainProps: { width: 270, height: 90, class: 'm2' },
            hashIsValid: false,

        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
        imgUrl() {
            return this.sideImg;
        },
        password1ToggleIcon() {
            return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
        password2ToggleIcon() {
            return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
    },
    async mounted() {
        this.callLoading(true);
        removeDarkMode();
        const { status } = await UserService.verifyHash(this.hash);
        if (status === 200) this.hashIsValid = true;

        this.callLoading(false);
    },
    methods: {
        togglePassword1Visibility() {
            this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password';
        },
        togglePassword2Visibility() {
            this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password';
        },
        async resetPassword() {
            this.callLoading(true);

            const { status } = await UserService.resetPassword({ new_password: this.password }, this.hash);

            if (status === 200) {
                this.modalSuccess('Senha alterada com sucesso');
                this.$router.push('/login');
            } else {
                this.modalError('Não foi possível alterar sua senha');
                this.callLoading(false);
            }
        },

    },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
